



































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';
import workspaceModule from '@/store/modules/workspaceModule';

@Component({
  components: {},
})
export default class TasqItem extends Vue {
  @Prop({ type: String, required: false }) tasqId?: string;

   @Prop({ type: Object, required: false }) tasqObject?: TasqJob;

  @Prop({ type: String, required: false }) tasqWellName?: string;

  @Prop({ type: Boolean, required: false }) tasqCompleted?: string;

  @Prop({ type: Boolean, required: false }) tasqProducing?: string;

   @Prop({ type: String, required: false, default: 'tasq' }) viewLevel?: string;

  @Prop({ type: Boolean, required: false }) isPadLevelPage?: boolean;

   @Prop({ type: Boolean, required: false }) isWellLevelPage?: boolean;

  @Prop({ type: Boolean, required: false, default: false }) setFixHeight?: boolean;

  showPadName = false;

  get isLoadingTasqResonses() {
	  return tasqsListModule.isLoadingTasqResponses;
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  get tasq(): any|undefined {
    if (this.tasqObject && this.tasqObject.wellName) {
      return this.tasqObject;
    }
    if (this.tasqId == null) {
      return undefined;
    }
    return tasqsListModule.tasqById(this.tasqId!);
  }

  // get enabledWells() {
  //   return assetsModule.enabledWells;
  // }

   get routeQueryType() {
    return this.$route.query.type;
  }

  // get producingTasq(): any|undefined {
  //   for (let x = 0; x < this.enabledWells.length; x++) {
  //     if (this.tasqWellName != null && this.enabledWells[x].wellName == this.tasqWellName) {
  //       return this.enabledWells[x];
  //     }
  //   }
  //   return null;
  // }

  get isTasqActive(): boolean {
    return this.tasq?.id === this.activeTasq?.id;
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
		  if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
	  return tasqsListModule.activeTasq;
    }

    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get didCheckTasq() {
	  if (this.tasq != null) {
		  return tasqsListModule.checkedTasqs.includes(this.tasq!.id);
	  }
	  return false;
  }

  get percentageForWaitingOn() {
    // console.log(this.tasq.wellName);
    if (this.tasq.waitingOnStartDate && this.tasq.waitingOnEndDate) {
      const startDate: any = new Date(this.tasq.waitingOnStartDate);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      const currentDate: any = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      const endDate: any = new Date(this.tasq?.waitingOnEndDate);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      const differenceStartToCurrent = Math.ceil((Math.abs(currentDate - startDate)) / (1000 * 60 * 60 * 24)) - 1;

      if ((differenceStartToCurrent) < 1) {
        return 0;
      }
      const differenceStartToEnd = Math.ceil(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
      // console.log((differenceStartToCurrent / differenceStartToEnd) * 100);
      const result = (differenceStartToCurrent / differenceStartToEnd) * 100;
      if (result > 100) {
        return 100;
      }
      return result;
    }

    return 0;
    // ts-ignore
  }

  get daysForWaitingOn() {
    // console.log(this.tasq.waitingOnEndDate);
    if (this.tasq.waitingOnStartDate && this.tasq.waitingOnEndDate) {
      // const startDate: any = new Date(this.tasq.waitingOnStartDate);
      const currentDate: any = new Date();
      const endDate: any = new Date(this.tasq?.waitingOnEndDate);
      // const differenceStartToCurrent = Math.ceil(Math.abs(currentDate - startDate) / (1000 * 60 * 60 * 24));
      const differenceStartToEnd = Math.ceil((Math.abs(endDate - currentDate)) / (1000 * 60 * 60 * 24));
      // console.log((differenceStartToCurrent / differenceStartToEnd) * 100);
      return differenceStartToEnd < 1 || currentDate > endDate ? 'Ready' : `${differenceStartToEnd}`;
    }

    return 0;
    // ts-ignore
  }

  get tasqScheduledData() {


	  for (let x = 0; x < workspaceModule.activeWorkspaces.length; x++) {
		  for (let y = 0; y < workspaceModule.activeWorkspaces[x].Tables.length; y++) {
			  for (let z = 0; z < workspaceModule.activeWorkspaces[x].Tables[y].Groups.length; z++) {
          for (let a = 0; a < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items.length; a++) {
              console.log('checking schedule')
				  if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID == this.tasq?.id) {
					  let isScheduled = false;
					  let startDate = '';
					  let endDate = '';
					  for (let b = 0; b < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns.length; b++) {
						  if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'STATUS' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response == 'Scheduled') {
                  isScheduled = true;
						  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'START_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
                  startDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response;
						  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'DUE_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
							  endDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response;
						  }
					  }
					  if (isScheduled) {
                var dateObj = new Date(startDate);
                var month = dateObj.getUTCMonth() + 1; // months from 1-12
                var day = dateObj.getUTCDate();
                var year = dateObj.getUTCFullYear();

                const newStartDate = `${month}/${day}/${year}`;

                var dateObj = new Date(endDate);
                var month = dateObj.getUTCMonth() + 1; // months from 1-12
                var day = dateObj.getUTCDate();
                var year = dateObj.getUTCFullYear();

                const newEndDate = `${month}/${day}/${year}`;
						  return `${newStartDate} • ${newEndDate}`;

                //   return startDate + " • " + endDate
					  }
				  }
			  	}
			  }
		  }
	  }
	  return '';
  }

  get isScheduledLater() {
	  for (let x = 0; x < workspaceModule.activeWorkspaces.length; x++) {
		  for (let y = 0; y < workspaceModule.activeWorkspaces[x].Tables.length; y++) {
			  for (let z = 0; z < workspaceModule.activeWorkspaces[x].Tables[y].Groups.length; z++) {
          for (let a = 0; a < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items.length; a++) {
				  if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID == this.tasq?.id) {
					  let isScheduled = false;
					  let startDate = '';
					  let endDate = '';
					  for (let b = 0; b < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns.length; b++) {
						  if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'STATUS' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response == 'Scheduled') {
                  isScheduled = true;
						  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'START_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
                  startDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response;
						  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'DUE_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
							  endDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response;
						  }
					  }
					  if (isScheduled) {
						  return true;
						  if ((new Date(startDate) > new Date() || (new Date(endDate) < new Date()))) {
							  return true;
						  }
					  }
				  }
			  	}
			  }
		  }
	  }
	  return false;
  }

  getOverridenRealTimeLabel(label) {
	  if (label.toLowerCase() == 'rtd') {
		  return 'Deferment';
	  }
	  if (label.toLowerCase() == 'hiline') {
		  return 'Line Pressure';
	  }
	  return label;
  }

  checkTasq() {
	  if (this.tasq != null && this.tasq.id) {
		  tasqsListModule.setIsBatchResponding(false);
		  tasqsListModule.checkTasq(this.tasq!.id);
		  tasqsListModule.setBatchResponseEnabled();
	  }

    //   this.$emit('check-tasq')
  }

  fullName() {
    if (this.tasq?.reassignedUserName) {
      const initialUserName = getNameByEmail(this.tasq.initialUserName).split(' ');
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
	  if (initialUserName.length > 1 && reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  } if (initialUserName.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}`;
	  } if (reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  }
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}`;
    }
	  return getNameByEmail(this.tasq?.username);
  }

  async created() {
    //   accountModule.getReassignmentList();
  }

  goToTasqPage(type) {
    if (this.isEditing) {
      this.checkTasq();
      return;
    }
    if (type == 'producing') {
      this.$router.push({
        name: 'Tasqs',
        params: {
          id: this.tasq?.wellName || '',
        },
        query: { type: 'producing', view: this.viewLevel },
      });
	  tasqsListModule.setIsEditing(false);
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
      assetsModule.setActiveProducingTasq(this.tasq);
    } else {
      this.$router.push({
        name: 'Tasqs',
        params: {
          id: this.tasq?.id || '',
        },
        query: { type: 'id', view: this.viewLevel },
      });
	  tasqsListModule.setIsEditing(false);
      assetsModule.setActiveProducingTasq('');
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
    }
  }
}
